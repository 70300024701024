@font-face {
  font-family: 'Whyte Inktrap';
  src: url('/fonts/Whyte-Inktrap-Regular.woff2') format('woff2'),
    url('/fonts/Whyte-Inktrap-Regular.woff') format('woff'),
    url('/fonts/Whyte-Inktrap-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Synt';
  src: url('/fonts/ABCSynt-Regular.woff2') format('woff2'),
    url('/fonts/ABCSynt-Regular.woff') format('woff'),
    url('/fonts/ABCSynt-Regular.ttf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte';
  src: url('/fonts/Whyte-Light.woff2') format('woff2'),
    url('/fonts/Whyte-Light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte';
  src: url('/fonts/Whyte-LightItalic.woff2') format('woff2'),
    url('/fonts/Whyte-LightItalic.woff') format('woff');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte';
  src: url('/fonts/Whyte-Book.woff2') format('woff2'),
    url('/fonts/Whyte-Book.woff') format('woff');
  font-style: normal;
  font-weight: 350;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte';
  src: url('/fonts/Whyte-BookItalic.woff2') format('woff2'),
    url('/fonts/Whyte-BookItalic.woff') format('woff');
  font-style: italic;
  font-weight: 350;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte';
  src: url('/fonts/Whyte-Medium.woff2') format('woff2'),
    url('/fonts/Whyte Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Whyte';
  src: url('/fonts/Whyte-MediumItalic.woff2') format('woff2'),
    url('/fonts/Whyte-MediumItalic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}
