.CaseStudy {
  &__inner-wrapper {
    @apply px-content-skinny;
    @screen tablet {
      @apply px-content-narrow;
    }
    @screen laptop {
      width: $body-width;
      margin: 0px auto;
      @apply px-0;
    }
  }
}
