.BaseButtonInner {
  svg {
    transition: 300ms cubic-bezier(0.77, 0, 0.175, 1) all;
  }
  &:hover {
    svg {
      transform: rotate(360deg);
    }
  }
}
