.ImageGridBlock {
  &__outer-wrapper {
    height: 0;
    overflow: hidden;
    padding-top: 62.5%;
    position: relative;
    &:hover {
      .ImageGridBlock__inner-wrapper--hovered {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &__inner-wrapper--hovered {
    opacity: 0;
    pointer-events: none;
    transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  &__inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .CaseStudy__inner-wrapper {
    @apply px-0;
  }
}
