.CaseStudyGridItemSkeleton {
  &__image {
    height: 0;
    overflow: hidden;
    padding-top: 62.5%;
    position: relative;
  }
  &____image-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
