@import 'fonts', 'vars';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'blocks', 'components', 'atoms', 'global';

.debug {
  border: solid peru;
}
