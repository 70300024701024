.Video {
  &__cursor--pause {
    @screen tablet {
      cursor: url('/icons/cursor-pause.svg') 60 30, auto;
    }
  }
  &__cursor--play {
    @screen tablet {
      cursor: url('/icons/cursor-play.svg') 60 30, auto;
    }
  }
  &__button-container {
    @apply justify-end;
  }
  &:focus-within {
    @apply cursor-default;
  }
  &__button-container:focus-within {
    @screen tablet {
      @apply justify-center items-center;
    }
  }
  &__play-button {
    pointer-events: all;
    @screen tablet {
      @apply opacity-0;
    }
    &:focus {
      @apply opacity-100;
    }
  }
  &__element--horizontal {
    aspect-ratio: 1440/900;
  }
  &__element--vertical {
    aspect-ratio: 764/970;
  }
}
