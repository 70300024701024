.Footer {
  a {
    transition: 100ms ease opacity;
    flex-shrink: 1;
    svg {
      transition: 200ms cubic-bezier(0.77, 0, 0.175, 1) all;
    }
    &:hover {
      svg {
        transform: rotate(360deg);
      }
    }
  }
  &__item-wrapper a {
    opacity: 1;
  }
  @screen laptop {
    /* these 3 css definitions are used to select previous & next sibling items */
    &__item-wrapper:hover a {
      opacity: 0;
    }
    &__item-wrapper:hover span.errorCTA {
      opacity: 0;
    }
    &__item-wrapper a:hover {
      opacity: 1;
    }
    &__item-wrapper a:hover ~ a {
      opacity: 0;
    }
    &__item-wrapper a:hover ~ span.errorCTA {
      opacity: 0;
    }
    /* these 2 css definitions are used to apply hover only to child anchor element - otherwise, the footer list items will have hover effect even the cursor is not directly on top of each element */
    &__item-wrapper {
      pointer-events: none;
    }
    &__item-wrapper a {
      pointer-events: auto;
    }
  }
}
