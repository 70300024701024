.TitledTextBlock {
  ol {
    counter-reset: list;
    display: table;

    @screen laptop {
      margin-left: calc(-1rem - 1ch);
    }

    li {
      list-style-type: none;
      counter-increment: list;
      display: table-row;

      &::before {
        content: counter(list) '.';
        display: table-cell;
        text-align: right;
        padding-right: 1rem;
      }
    }
  }

  a {
    text-decoration-color: currentColor;
    text-decoration-thickness: 0.05rem;
    text-underline-offset: 2px;
    text-decoration-style: solid;
    text-decoration-line: underline;
  }
}
