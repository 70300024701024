body {
  @apply font-whyte;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  color: #dcdcdc;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6,
p,
button,
::placeholder {
  font-weight: 350;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
}

h1 {
  font-size: 2rem;
  line-height: 2.4rem;
}

button,
a {
  @apply transition-all duration-200;
}

b,
strong {
  @apply font-medium;
}

.content-max-width {
  max-width: 100rem;
  margin: 0px auto;
}

ol > li:before {
  content: '';
}
