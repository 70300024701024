.LandingTab {
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  @screen tablet {
    transition: all 0.3s ease-out;
  }
  &:hover {
    .LandingTab__content {
      opacity: 1 !important;
    }
  }
}
